/**
 * Navigation functionality
 */

export default {
    init() {
        document.getElementById('navToggle').addEventListener('click', (e) => this.toggle(e));
    },

    toggle(e) {
        e.preventDefault();
        document.querySelector('body').classList.toggle('navbar-active');
    },
};
