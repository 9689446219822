<template>
    <div id="menu-content-wrapper" class="relative mx-auto mb-36 flex max-w-[1372px] flex-wrap gap-x-12 px-4 md:flex-nowrap">
        <a name="menu-top" class="absolute -mt-16"></a>

        <MenuSidebar :location="items[0].name" :categories="categories" :dinein-download="items[0].dinein_download" :togo-download="items[0].togo_download" />

        <div class="lg:pt-3.5" id="menu-content">
            <slot name="banner"></slot>
            <transition name="catSection" v-on:after-leave="updateActive">
                <div id="menu-content-sections" v-show="!loading">
                    <section v-for="(category, index) in categories" class="mt-9 first:mt-0" :id="'menu-' + category.slug" :key="'cat-' + index">
                        <h3 class="lg:[text-46px] relative mb-8 text-center text-[28px] before:absolute before:left-0 before:top-1/2 before:mt-1 before:h-0.5 before:w-full before:bg-teal before:content-[''] md:text-[36px] xl:text-[60px]">
                            <span class="relative z-20 bg-white px-7" v-html="category.name"></span>
                        </h3>
                        <p v-if="category.description" v-html="category.description" class="mb-8 text-center"></p>
                        <div class="grid gap-8 lg:grid-cols-2 xl:grid-cols-3">
                            <menu-item v-for="item in category.items" :item="item" :key="item.id"></menu-item>
                        </div>
                    </section>
                </div>
            </transition>

            <div id="menu-content-footer" class="align-center">
                <a href="#menu-top" class="button button-light">Back To Top</a>
            </div>

            <a href="tel:7139756082" class="lg:hidden max-w-[200px] block mt-8 mx-auto">
                <img :src="'/app/themes/pappasburger/images/badge-togo.png'" alt="Order To-Go" />
            </a>
        </div>
    </div>
</template>

<script>
import SmoothScroll from 'smooth-scroll';
import MenuItem from './menu-item.vue';
import MenuSidebar from './menu-sidebar.vue';

export default {
    components: { MenuItem, MenuSidebar },

    /** @type {Array} component properties */
    props: ['items'],

    /** component data */
    data() {
        return {
            activeLocation: 0,
            scroll: null,
            loading: false,
            nextLocation: null,
            locationMenuActive: false,
        };
    },

    mounted() {
        /** @type {SmoothScroll} smooth-scroll instance */
        this.scroll = new SmoothScroll('#menu-nav a', {
            speed: 300,
            offset: 64,
            updateURL: false,
        });
    },

    /**
     * Component instance created lifecycle hook
     */
    created() {
        if (!window.location.hash) return;

        const targetSlug = window.location.hash.replace('#', '');
        const index = this.validSlugs.indexOf(targetSlug);

        if (index > -1) this.activeLocation = index;
    },

    /** @type {Object} computed properties */
    computed: {
        /** active menu categories */
        categories() {
            return this.items[this.activeLocation].categories;
        },

        /** valid location slugs */
        validSlugs() {
            let slugs = [];

            this.items.forEach((location) => {
                slugs.push(location.slug);
            });

            return slugs;
        },
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Handle updating the current location
         *
         * @param {Number} index requeted location index
         */
        setLocation(index) {
            this.loading = true;
            this.nextLocation = index;
        },

        /**
         * Update the active location
         * This is called once the fade out transition has completed
         */
        updateActive() {
            this.activeLocation = this.nextLocation;
            this.loading = false;

            window.history.pushState(null, null, '#' + this.items[this.activeLocation].slug);
        },
    },
};
</script>
