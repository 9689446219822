import '@pappas/eclub/dist/pappas-eclub.css';
import '../styles/burger.scss';

import { createApp } from 'vue';
import LeadsForm from '@pappas/leads-form';

import Navigation from './modules/navigation';
import Locations from './modules/locations';
import Ingredients from './modules/ingredients';

import MenuContent from './components/menu-content.vue';
import HomeMasthead from './components/home-masthead.vue';
import Faqs from './components/faqs.vue';

import HamburgerIcon from '../images/hamburger.svg';

import pappasEclub from '@pappas/eclub';
// import pappasEclub from '../../../pappas-eclub-form/src/plugin';

// initiate modules that bind after content load
window.addEventListener('DOMContentLoaded', (e) => {
    Navigation.init();
    Ingredients.init();
});

const app = createApp();

app.use(LeadsForm);
app.use(pappasEclub);

app.component('menu-content', MenuContent);
app.component('home-masthead', HomeMasthead);
app.component('faqs', Faqs);
app.component('hamburger-icon', HamburgerIcon);

app.mount('#app');

// google maps callback
window.initLocationsMap = () => Locations.init();

window.onload = () => document.body.classList.add('is-loaded');
