/**
 * Locations map functionality
 */

export default {
    /** @type {Element} container element */
    el: null,

    /** @type {Map} google map instance */
    map: null,

    /** @type {Array} google map markers */
    markers: [],

    /** @type {Object} marker coordinates */
    // coords: {lat: 29.642822, lng: -95.5439707},
    locations: [
        {
            name: 'Pappas Burger Houston',
            lat: 29.73744,
            lng: -95.48112,
        },
    ],

    /** @type {Object} map center coordinates */
    center: { lat: 29.737, lng: -95.481 }, // currently set to Hermann Park

    /** @type {Number} google map zoom level */
    zoom: 11,

    /**
     * Initialize the map
     */
    init() {
        this.el = document.getElementById('locations-map');

        if (!this.el) return;

        this.map = new google.maps.Map(this.el, {
            zoom: this.zoom,
            center: this.center,
            disableDefaultUI: true,
        });

        this.addMarkers();
    },

    /**
     * Add location markers to the map
     */
    addMarkers() {
        this.locations.forEach((location) => {
            this.markers.push(
                new google.maps.Marker({
                    position: { lat: location.lat, lng: location.lng },
                    map: this.map,
                    title: location.name,
                }),
            );
        });
    },
};
