/**
 * Ingredients section carousel
 */

import { tns } from 'tiny-slider/src/tiny-slider';

export default {
    /** @type {Glide} glide instance */
    slider: null,

    /** @type {String} wrapper element selector */
    wrapperSelector: '#ingredients-slider',

    /** @type {Node} slider items wrapper element */
    $wrapper: null,

    active: false,

    breakpoint: 640,

    /**
     * Initialization
     */
    init() {
        this.$wrapper = document.querySelector(this.wrapperSelector);

        if (!this.$wrapper) return;

        window.onresize = (e) => this.resize();

        this.resize();
    },

    resize() {
        let activate = window.innerWidth < this.breakpoint + 1;

        if (activate && !this.active) this.activate();
        else if (!activate && this.active) this.deactivate();
    },

    activate() {
        if (!this.slider) {
            this.slider = tns({
                container: this.$wrapper,
                center: true,
                autoHeight: true,
                navPosition: 'bottom',
                responsive: {
                    640: {
                        disable: true,
                    },
                },
            });
        } else {
            this.slider.rebuild();
        }

        this.active = true;
    },

    deactivate() {
        if (this.slider) {
            this.slider.destroy();
            this.slider = null;
        }

        this.active = false;
    },
};
