<template>
    <component :is="wrapperElType" v-bind="wrapperAttrs">
        <div v-for="(slide, index) in items" class="slide-image" :class="{ active: active === index }">
            <div class="slide-image-lg" :style="{ backgroundImage: 'url(' + slide.image_desktop.url + ')' }"></div>
            <div class="slide-image-sm" :style="{ backgroundImage: 'url(' + slide.image_mobile.url + ')' }"></div>
        </div>
        <div class="ribbon">
            <div class="ribbon-body" :style="{ width: ribbonBodyWidth + 'px' }">
                <div v-for="(slide, index) in items" class="ribbon-inner" :class="{ active: active === index }" ref="ribbonInner">
                    <div class="slide-headline" ref="headlines">
                        <h6 v-if="slide.subheadline" v-html="slide.subheadline"></h6>
                        <h2 v-html="slide.headline"></h2>
                    </div>
                </div>
            </div>
        </div>
    </component>
</template>

<script>
export default {
    /** @type {Array} component properties */
    props: ['items'],

    /** component data */
    data() {
        return {
            active: 0,
            timer: null,
            numItems: this.items.length,
            ribbonBodyWidth: 500,
        };
    },

    computed: {
        activeSlide() {
            return this.items[this.active];
        },

        linkActiveSlide() {
            return this.activeSlide.link;
        },

        wrapperElType() {
            return this.linkActiveSlide ? 'a' : 'div';
        },

        wrapperAttrs() {
            let attrs = {
                id: 'home-masthead',
            };

            if (this.linkActiveSlide) {
                attrs.href = this.activeSlide.link;

                attrs.target = this.activeSlide.link_external ? '_blank' : '_self';
            }

            return attrs;
        },
    },

    /**
     * Instance mounted lifecycle hook
     */
    mounted() {
        this.timer = window.setInterval(this.next, 7000);
        this.calculateRibbonBodyWidth();
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        next() {
            this.active >= this.numItems - 1 ? (this.active = 0) : this.active++;
        },

        calculateRibbonBodyWidth() {
            this.$refs.headlines.forEach((el) => {
                if (el.offsetWidth > this.ribbonBodyWidth) this.ribbonBodyWidth = el.offsetWidth;
            });
        },
    },
};
</script>
