<template>
    <div class="menu-item text-center">
        <h5 class="text-[22px] leading-[25px] text-teal" v-html="item.name"></h5>
        <p class="m-0" v-if="item.description" v-html="item.description"></p>
        <p class="m-0 mt-2" v-if="item.price" v-html="item.price"></p>

        <ul v-if="item.options" class="m-0 mt-1 p-0 text-sm leading-4 text-teal">
            <li v-for="option in item.options" :key="option.id" class="flex justify-center gap-x-1 leading-4">
                <span>{{ option.name }}</span>
                <i v-if="option.name && option.price">&ndash;</i>
                <span v-if="option.price" class="menu-item-option-price">{{ option.price }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
};
</script>
