<template>
    <div class="mb-6 flex w-full flex-col md:max-w-[270px] lg:mb-0 lg:min-w-[270px]">
        <div class="relative mb-4 hidden lg:block">
            <div class="relative py-1.5 pl-[60px] font-script text-[23px] leading-[1.3]">
                <img :src="'/app/themes/pappasburger/images/marker.png'" alt="map marker" class="absolute left-4 top-0 z-20 h-auto w-[34px]" />
                <span class="text-red">Location</span>
            </div>
            <div class="relative bg-teal px-8 py-6 font-secondary font-bold uppercase leading-none tracking-wider text-tan">{{ location }}</div>
        </div>

        <div class="w-full bg-teal-light">
            <ul class="m-0 list-none px-0 py-5">
                <li v-for="category in categories" class="text-center lg:text-left">
                    <a :href="'#menu-' + category.slug" class="block py-1.5 pl-8 pr-4 text-[15px] font-bold uppercase leading-tight tracking-wider" data-scroll>
                        {{ category.name }}
                    </a>
                </li>
            </ul>
        </div>

        <div class="mx-auto w-full max-w-[200px]">
            <div class="my-6 mb-8 flex flex-col gap-y-2.5">
                <a class="download-button" v-if="dineinDownload" :href="dineinDownload" target="_blank" rel="noopener">
                    <span>Download Menu</span>
                    <DownloadIcon class="size-4" />
                </a>

                <a class="download-button" v-if="togoDownload" :href="togoDownload" target="_blank" rel="noopener">
                    <span>To-Go Menu</span>
                    <DownloadIcon class="size-4" />
                </a>
            </div>

            <a href="/catering" class="group hidden lg:block">
                <img :src="'/app/themes/pappasburger/images/badge-togo.png'" alt="Order To-Go" class="w-full transition-all group-hover:saturate-150" />
            </a>

            <a href="https://pappasdelivery.com" target="_blank" rel="noopener" class="group mt-8 hidden lg:block">
                <img :src="'/app/themes/pappasburger/images/badge-delivery.png'" alt="Pappas Delivery" class="w-full transition-all group-hover:saturate-150" />
            </a>
        </div>

        <div class="mx-auto hidden w-full max-w-[200px] grow lg:block">
            <a href="#menu-top" class="button button-light mt-32 w-full max-w-[200px] text-center lg:mt-8">Back to Top</a>
        </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue';
import DownloadIcon from '../../images/download-icon.svg';
import Sticky from 'sticky-js';

const props = defineProps({
    location: { type: String, required: true },
    categories: { type: Array, required: true },
    dineinDownload: { type: String, default: null },
    togoDownload: { type: String, default: null },
});

onMounted(() => {
    new Sticky('.button-light');
});
</script>

<style>
.download-button {
    @apply flex w-full items-center justify-center gap-x-2 border-2 border-solid border-teal pb-1.5 pt-2 text-sm font-semibold uppercase leading-tight transition-colors hover:bg-teal-light hover:text-teal;
}
</style>
