<template>
    <div id="faqs-wrapper">
        <div v-for="(item, index) in items" class="faq" :class="{ active: index === active }" ref="faq">
            <div class="faq-header" @click.prevent="active === index ? (active = null) : (active = index)">
                <h6 v-html="item.question"></h6>
            </div>
            <div class="faq-content" :style="itemStyle(index)"><div v-html="item.answer"></div></div>
        </div>
    </div>
</template>

<script>
export default {
    /** @type {Object} component properties */
    props: {
        /** @type {Array} faq items */
        items: {
            type: Array,
            default: [],
        },
    },

    /** component data */
    data() {
        return {
            /** @type {Number} active item index */
            active: null,
        };
    },

    /** @type {Object} component methods */
    methods: {
        /**
         * determine item content style
         * @param  {Number} index
         * @return {Object}
         */
        itemStyle(index) {
            if (index === this.active) {
                return {
                    maxHeight: this.$refs.faq[index].querySelector('.faq-content > div').offsetHeight + 'px',
                };
            }

            return null;
        },
    },
};
</script>
